<template>
    <div>
        <Navigation />
        <!-- 一言一文 -->
        <div class="banner">
            <img :src="imgUrl" alt="">
        </div>
        <!-- <div> -->
        <!-- 核心功能概括 -->
        <div class="brief_box">
            <img src="../assets/word/brief_title.png" alt="" class="title">
            <p class="title_remark">致力于帮助更多有需求的中小企业进行软文、广告语、标题、脚本的智能生成，减少时间成本</p>
            <ul class="flex brief_list">
                <li v-for="(item, index) in briefList" :key="index">
                    <img :src="item.imgUrl" alt="">
                    <p class="title1">{{ item.type }}</p>
                    <p class="title2">{{ item.title }}</p>
                </li>
            </ul>
        </div>
        <!-- 功能界面展示效果 -->
        <div class="Fnbanner_box">
            <img src="../assets/word/fntitle.png" alt="" class="title">
            <ul class="flex Fnbanner_list">
                <li :class="bannerIndex == index ? 'active' : ''" v-for="(item, index) in bannerTitle" :key="index">
                    <img :src="require('../assets/word/ai-icon.png')" alt="">
                    <p>{{ item }}</p>
                </li>
            </ul>
            <div class="computed_box">
                <!-- <div class="ct_box"> -->
                <el-carousel indicator-position="none" trigger="click" arrow="always"
                    @change="((pre, next) => { tabShow(pre, next) })">
                    <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
                        <img :src="item" alt="" style="width: 100%; object-fit: cover;">
                    </el-carousel-item>
                </el-carousel>
                <!-- </div> -->
            </div>
            <button class="button" @click="goWordOp">开启AI智能之旅</button>
        </div>

        <!-- 功能介绍 -->
        <div style="text-align: center;margin: 5rem 0; margin-bottom: 10rem;">
            <img src="../assets/word/IntroduceTitle.png" alt="" class="title2">
            <p class="title_remark">致力于帮助更多有需求的中小企业进行软文、广告语、标题、脚本的智能生成，减少时间成本</p>
        </div>
        <!-- 写简介 -->
        <div class="FnIntroduce_box1 flex">
            <div class="ct_box flex">
                <div class="ct_1" :class="scrollTopNum > minAn1 ? 'box_left' : ''">
                    <img src="../assets/word/01-icon.png" alt="">
                    <h3>写简介-简介AI制作，帮您一键生成免构思。</h3>
                    <p>主要以：企业简介、产品简介、个人简介三大类为主，通过选择录入所属行业，主营业务、擅长领域、个人荣耀、适用人群等关键词，一键生成所需简介。</p>
                </div>
                <div class="ct_2" :class="scrollTopNum > minAn1 ? 'box_right' : ''">
                    <img src="../assets/word/banner1.png" alt="" style="width: 100%;">
                </div>
            </div>
        </div>
        <!-- 写文稿 -->
        <div class="FnIntroduce_box2 flex">
            <div class="ct_box flex">
                <div class="ct_2" :class="scrollTopNum > minAn2 ? 'box_left' : ''">
                    <img src="../assets/word/banner2.png" alt="" style="width: 100%;">
                </div>
                <div class="ct_1" :class="scrollTopNum > minAn2 ? 'box_right' : ''">
                    <img src="../assets/word/02-icon.png" alt="">
                    <h3>写文稿-文稿AI创作，帮您构思优化文稿内容，一键生成做参考。</h3>
                    <p>主要以：记叙文与议论文为主，通过总分结构、递进结构、并列结构、对照结构的选择，录入一句标题，填写关键词即可一键生成所需文稿。
                    </p>
                </div>
            </div>
        </div>
        <!-- 写短语 -->
        <div class="FnIntroduce_box1 flex">
            <div class="ct_box flex">
                <div class="ct_1" :class="scrollTopNum > minAn3 ? 'box_left' : ''">
                    <img src="../assets/word/03-icon.png" alt="">
                    <h3>写短语－短语AI创作，助力您节日营销文案新提升。</h3>
                    <p>主要以：广告语与祝福语两大类进行划分，录入相关内容与场景，即可一键生成所需内容。</p>
                </div>
                <div class="ct_2" :class="scrollTopNum > minAn3 ? 'box_right' : ''">
                    <img src="../assets/word/banner3.png" alt="" style="width: 100%;">
                </div>
            </div>
        </div>
        <!-- 写要点 -->
        <div class="FnIntroduce_box2 flex">
            <div class="ct_box flex">
                <div class="ct_2" :class="scrollTopNum > minAn4 ? 'box_left' : ''">
                    <img src="../assets/word/banner4.png" alt="" style="width: 100%;">
                </div>
                <div class="ct_1" :class="scrollTopNum > minAn4 ? 'box_right' : ''">
                    <img src="../assets/word/04-icon.png" alt="">
                    <h3>写文稿-文稿AI创作，帮您构思优化文稿内容，一键生成做参考。</h3>
                    <p>主要以：记叙文与议论文为主，通过总分结构、递进结构、并列结构、对照结构的选择，录入一句标题，填写关键词即可一键生成所需文稿。
                    </p>
                </div>
            </div>
        </div>
        <!-- 写脚本 -->
        <div class="FnIntroduce_box1 flex">
            <div class="ct_box flex">
                <div class="ct_1" :class="scrollTopNum > minAn5 ? 'box_left' : ''">
                    <img src="../assets/word/05-icon.png" alt="">
                    <h3>写短语－短语AI创作，助力您节日营销文案新提升。</h3>
                    <p>主要以：广告语与祝福语两大类进行划分，录入相关内容与场景，即可一键生成所需内容。</p>
                </div>
                <div class="ct_2" :class="scrollTopNum > minAn5 ? 'box_right' : ''">
                    <img src="../assets/word/banner5.png" alt="" style="width: 100%;">
                </div>
            </div>
        </div>
        <footerBot />

        <!-- </div> -->
    </div>
</template>

<script>
import { goAi } from '@/util/goAi'

export default {
    name: '',
    created() { },
    mounted() {
        //你可能疑问，div中有@mousewheel可以触发，为什么还要初始执行一次？
        //这里初始执行一次的原因，需要看方法里面的内容去理解。当然实际操作也能看到效果
        window.addEventListener("scroll", this.scrolling);
        var obj1 = document.getElementsByClassName('FnIntroduce_box1')
        var obj2 = document.getElementsByClassName('FnIntroduce_box2')
        // console.log(obj2[0].offsetTop);
        // 获取第一个
        this.minAn1 = obj1[0].offsetTop; +110// 最小
        // this.maxAn1 = obj1[0].clientHeight + obj1[0].offsetTop; //最大
        // 获取第二个
        this.minAn2 = obj2[0].offsetTop + 110;// 最小
        // this.maxAn2 = obj2[0].clientHeight + obj2[0].offsetTop + 110; //最大

        // 获取第三个
        this.minAn3 = obj1[1].offsetTop + 300;// 最小
        // this.maxAn3 = obj1[1].clientHeight + obj1[1].offsetTop + 400 //最大
        // 获取第四个
        this.minAn4 = obj2[1].offsetTop + 300;// 最小
        // this.maxAn4 = obj2[1].clientHeight + obj2[1].offsetTop + 400 //最大
        // 获取第五个
        this.minAn5 = obj1[2].offsetTop + 700;// 最小
        // this.maxAn5 = obj1[2].clientHeight + obj1[2].offsetTop //最大
        // console.log(this.minAn5, this.maxAn5);
        // console.log(rectObject);
        this.BannerImgFn()
    },
    data() {
        return {
            briefList: [
                {
                    imgUrl: require('../assets/word/dyx-icon.png'),
                    type: '多样性',
                    title: 'AI写作可以生成各种类型的文本，包括新闻报道、科技评论、小说、广告文案等.AI 写作的模型可以通过训练来适应不同的文体和领域使得生成的文本具有多样性。'
                },
                {
                    imgUrl: require('../assets/word/gjd-icon.png'),
                    type: '高精度',
                    title: '由于AI写作是基于机器学习算法的，因此它可以在保持高质量的同时，确保内容的准确性。'
                },
                {
                    imgUrl: require('../assets/word/gxh-icon.png'),
                    type: '高效性',
                    title: 'AI创作让用户可以快速完成各类创作任务，如拼写检查、语法纠正等，能够自动识别用户的需求和意图，自动完成一些基本的创作任务。'
                },
                {
                    imgUrl: require('../assets/word/gxx-icon.png'),
                    type: '扩展性',
                    title: 'AI写作可以根据用户的反馈进行改进通过算法进行优化，以最快的反应速度进行文案反馈。'
                },
                {
                    imgUrl: require('../assets/word/kdz-icon.png'),
                    type: '自动化',
                    title: 'AI写作可以实现自动化生成文本，大大提高了写作效率专统的写作过程需要人工思考、构思、撰写和修改，而AI写作可以通过训练模型自动分析和生成文字，减轻了写作压力。'
                },
                {
                    imgUrl: require('../assets/word/kzq-icon.png'),
                    type: '个性化',
                    title: 'AI写作可以根据用户的需求和偏好，通过分析用户的输入和数据，AI 可以根据用户的口味和风格生成符合用户需求的文章，满足用户的个性化需求'
                },
                {
                    imgUrl: require('../assets/word/scb-icon.png'),
                    type: '语言处理强',
                    title: 'AI写作可以生成各种类型的文本，包括新闻报道、科技评论、小说、广告文案等.AI 写作的模型可以通过训练来适应不同的文体和领域使得生成的文本具有多样性。'
                },
                {
                    imgUrl: require('../assets/word/sdk-icon.png'),
                    type: '省成本',
                    title: '相比于人工写作，AI写作可以节省很多成本，比如时间成本、人力成本、硬件成本等'
                },
                {
                    imgUrl: require('../assets/word/yyclq-icon.png'),
                    type: '速度快',
                    title: '相比于人工写作，AI写作的速度更快，可以在几秒钟内生成一篇文章，而人工写作可能需要几个小时或几天。'
                },
                {
                    imgUrl: require('../assets/word/zdh-icon.png'),
                    type: '可定制',
                    title: 'AI写作可以根据用户的要求，进行模型的定制和调整用户可以根据自己的需求，对模型进行训练和优化，使得生成的文本更加符合用户的要求。'
                },
            ],
            bannerTitle: ['简介创作', '文稿初创', '短语创作', '文稿提炼', '脚本创作',],
            bannerImg: [
                require('../assets/word/banner1.png'),
                require('../assets/word/banner2.png'),
                require('../assets/word/banner3.png'),
                require('../assets/word/banner4.png'),
                require('../assets/word/banner5.png')
            ],
            imgUrl: '',
            bannerIndex: 0,
            // 滚动条高度
            scrollTopNum: 0,
            minAn1: 0,
            minAn2: 0,
            minAn3: 0,
            minAn4: 0,
            minAn5: 0,
            user_info: {}
        }
    },
    methods: {

        tabShow(pre, next) {
            // console.log(pre, next);
            this.bannerIndex = pre
        },
        scrolling() {
            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 滚动条滚动的距离
            // let scrollStep = scrollTop - this.oldScrollTop;
            // 更新——滚动前，滚动条距文档顶部的距离
            // this.oldScrollTop = scrollTop;
            // if (scrollStep > 0) {
            //     this.scrollTopNum = scrollStep
            // }
            // 判断是否到了最顶部
            // if (scrollTop <= 0) {
            //     this.scrollTopNum = 0
            // }
            this.scrollTopNum = scrollTop
            // setTimeout(() => {
            //     return console.log(this.scrollTopNum);
            // }, 1000);


        },
        // 去功能选择页面
        async goWordOp() {
            // const aiStatus = await goAi()
            // if (aiStatus == 'ok') {
            this.$router.push('/wordNav')
            // } else {
            //     return this.$store.state.loginShow = true
            // }
        },
        BannerImgFn() {
            this.curlGet('/api/advert/detail', {
                mark: 'word_banner_01'
            }).then(res => {
                if (res.data.code) {
                    this.imgUrl = res.data.data.image
                }
            })
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/Word'
</style>